import React, { useEffect, useState } from "react";
import { Upload, Icon, Row, Popover, Button, Tooltip } from "antd-min";
import moment from "moment";
import classNames from "classnames";
import XLSX from "xlsx";
import { CellType, GroupRow } from "wijmo/wijmo.grid";
import _ from "lodash";
import {
    GLGlobal,
    GLLocale,
    GLUtil,
    MessageHelper,
    NotificationType,
    LanguageDateFormat
} from "gl-commonui";
import { RcFile } from "antd/lib/upload/interface";
import { useHistory } from "@app/hooks";
import { MainTitle, Container } from "@app/components";
import { BlobHelper, CommonHelper, fmtMsg, TYPES } from "@app/util";
import { RewardPointsLocale } from "@app/locales/localeid";
import { useService } from "@app/hooks";
import {
    IRewardPointsService,
    CampusPointExcelModel,
    ValidateBulkModelResponse
} from "@app/service/reward-points";
import { PathConfig } from "@app/config/pathconfig";
import { ColumnNames, detectInvalidRecord, isGuid } from "../constants";
import { WijmoGrid } from "@app/components";
import "./bulk-add-points.less";

const { Grid, Column } = WijmoGrid;

const { Dragger } = Upload;

const BulkAddPoints = () => {
    let history = useHistory();
    const [dataUploaded, setDataUploaded] = useState<CampusPointExcelModel[]>(
        []
    );
    const [loading, setLoading] = useState(false);
    const [invalidRecords, setInvalidRecords] = useState<
        CampusPointExcelModel[]
    >([]);
    const [validCampusFromServer, setValidCampusFromServer] = useState<
        ValidateBulkModelResponse[]
    >([]);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [fileList, setFileList] = useState([]);
    const rewardPointService = useService<IRewardPointsService>(
        TYPES.IRewardPointsService
    );

    const to_json = workbook => {
        const resultModel = { result: [], msg: null };
        if (workbook.SheetNames.length > 1) {
            resultModel.msg = fmtMsg({
                id: RewardPointsLocale.RewardPointsNotiFileSheet
            });
            return resultModel;
        }

        const sheetToConvert = workbook.Sheets[workbook.SheetNames[0]];
        const head = [
            ColumnNames.CampusId,
            ColumnNames.SchoolName,
            ColumnNames.CampusName,
            ColumnNames.Point,
            ColumnNames.CreationDate,
            ColumnNames.Description
        ];
        const roa = XLSX.utils.sheet_to_json(sheetToConvert, {
            header: head,
            defval: ""
        });
        if (roa.length) resultModel.result = roa;
        // remove first rows which for column name
        resultModel.result.splice(0, 1);
        resultModel.result = resultModel.result.map(m => {
            if (
                m.parentPhoneNumber !== null &&
                m.parentPhoneNumber !== undefined
            ) {
                m.parentPhoneNumber = m.parentPhoneNumber.toString();
            }
            return m;
        });
        return resultModel;
    };

    const processExcel = data => {
        const workbook = XLSX.read(data, {
            type: "binary",
            cellDates: true
        });

        return to_json(workbook);
    };
    const beforeUpload = (file: RcFile) => {
        const extValid =
            file.type ==
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            file.type == "application/vnd.ms-excel" ||
            file.type == ".csv";
        if (!extValid) {
            MessageHelper.Message(
                NotificationType.Failed,
                fmtMsg(RewardPointsLocale.RewardPointsNotiFileType)
            );
            return false;
        }

        const isLt2MB = file.size / 1024 < 2048;
        if (!isLt2MB) {
            MessageHelper.Message(
                NotificationType.Failed,
                fmtMsg(
                    { id: RewardPointsLocale.RewardPointsNotiFileSize },
                    { fileSize: 2 }
                )
            );
            return false;
        }

        const reader = new FileReader();

        reader.onload = e => {
            var contents = processExcel(e.target.result);
            if (contents.msg != null) {
                MessageHelper.Message(NotificationType.Warning, contents.msg);
            } else {
                setUploadedData(contents.result, file);
            }
        };
        reader.readAsBinaryString(file);
        return false;
    };

    const setUploadedData = async (data: any[], file) => {
        setFileList(prevList => [...prevList, file]);
        // add(1, "hours"): https://github.com/SheetJS/sheetjs/issues/1470
        const validData = await validateData(
            data.map(item => ({
                ...item,
                campusId: item.campusId.toLowerCase()
            }))
        );
        const formattedData = validData.map(item => {
            return {
                ...item,
                creationDate:
                    typeof item.creationDate.getMonth !== "function"
                        ? item.creationDate
                        : moment(
                              moment(item.creationDate).add(1, "hours")
                          ).format(LanguageDateFormat[GLGlobal.intl.locale])
            };
        });
        setDataUploaded(formattedData);
    };

    const validateData = async (data: CampusPointExcelModel[]) => {
        try {
            // filter these record which has the valid points
            setLoading(true);
            const dataWithPoints = data.map((record, index) => ({
                ...record,
                order: index + 1
            }));

            // query list valid campusName, schoolName, campusId from server
            let validCampuses = validCampusFromServer;
            const response = await rewardPointService.validateBulk({
                regionId: GLUtil.pathParse(PathConfig.Region).regionId,
                campusIds: dataWithPoints
                    .map(item => item.campusId)
                    .filter(guid => isGuid(guid))
            });
            validCampuses = response.map(item => ({
                ...item,
                campusId: item.campusId.toLowerCase()
            }));
            setValidCampusFromServer(validCampuses);
            // Detect invalid record :
            // campusId, campusName, schoolName from excel is valid or not based on server response
            // description, point, date from excel is valid or not by client code
            const invalidRecords = dataWithPoints.filter(
                detectInvalidRecord(validCampuses)
            );
            setInvalidRecords(invalidRecords);
            setLoading(false);
            return dataWithPoints;
        } catch (error) {
            setLoading(false);
            console.log("error", error);
            clearFile();
        }
    };

    const props = {
        name: "file",
        multiple: false,
        accept: ".xls,.xlsx,.csv",
        beforeUpload,
        fileList,
        disabled: fileList.length > 0
    };
    const downloadFormatFile = () => {
        const regionId = GLUtil.pathParse(PathConfig.Region).regionId;
        rewardPointService
            .downloadFormat({ regionId, language: GLGlobal.intl.locale })
            .then(data => {
                CommonHelper.isMobile()
                    ? BlobHelper.saveFileOnMobile(data)
                    : BlobHelper.saveFileSurvey(data);
            })
            .catch(er => {
                console.log("er", er);
            });
    };

    const formatItem = (s, e) => {
        if (
            e.panel.cellType === CellType.Cell &&
            !(s.rows[e.row] instanceof GroupRow)
        ) {
            const col = s.columns[e.col];
            switch (col.binding) {
                case ColumnNames.CreationDate: {
                    if (Date.parse(e.cell.innerHTML)) {
                        e.cell.innerHTML =
                            '<span class="v-transform">' +
                            moment(e.cell.innerHTML).format(
                                LanguageDateFormat[GLGlobal.intl.locale]
                            ) +
                            "</span>";
                    }
                }
            }
        }
    };

    const rewardColsGenerator = () => {
        const cols = [
            <Column
                binding={ColumnNames.Order}
                header={"Order"}
                key={"1"}
                width="3*"
                align="left"
            />,
            <Column
                binding={ColumnNames.SchoolName}
                header={fmtMsg(RewardPointsLocale.RewardPointsColumnSchool)}
                width="9*"
                key={"2"}
            />,
            <Column
                binding={ColumnNames.CampusName}
                header={fmtMsg(RewardPointsLocale.RewardPointsColumnCampus)}
                width="9*"
                key={"3"}
            />,
            <Column
                binding={ColumnNames.Point}
                header={fmtMsg(RewardPointsLocale.RewardPointsColumnPoints)}
                width="8*"
                key={"4"}
                align="left"
            />,
            <Column
                binding={ColumnNames.CreationDate}
                header={fmtMsg(RewardPointsLocale.RewardPointsColumnDate)}
                width="9*"
                key={"5"}
            />,
            <Column
                binding={ColumnNames.Description}
                header={fmtMsg(RewardPointsLocale.RewardPointsColumnDesc)}
                width="11*"
                key={"6"}
                render={text => {
                    const fullDesc = text;
                    let decsDisplay = <span>{fullDesc}</span>;
                    if (fullDesc.length > 50) {
                        decsDisplay = (
                            <Tooltip
                                overlayClassName="bulk-add-points-page__grid--tooltip"
                                title={fullDesc}
                            >
                                <span>
                                    {`${fullDesc.substring(0, 50)} ...`}
                                </span>
                            </Tooltip>
                        );
                    }
                    return decsDisplay;
                }}
                align="left"
            />
        ];
        return cols;
    };

    const handleVisibleChange = visible => {
        setPopoverVisible(visible);
    };

    const onCommit = async () => {
        try {
            await rewardPointService.createBulkPoint({ bulk: dataUploaded });
            MessageHelper.Message(
                NotificationType.Success,
                fmtMsg(RewardPointsLocale.RewardPointsNotiUploaded)
            );
            clearFile();
            redirectToPointManager();
        } catch (error) {
            console.log("error", error);
        }
    };

    const redirectToPointManager = () => {
        const regionId = GLUtil.pathParse(PathConfig.Region).regionId;
        history.push({
            pathname: GLUtil.pathStringify(PathConfig.RegionRewardPoints, {
                regionId
            })
        });
    };

    const clearFile = () => {
        setFileList([]);
        setDataUploaded([]);
        setInvalidRecords([]);
    };

    return (
        <Container fullWidth fullHeight className="bulk-add-points-page">
            <MainTitle
                plain={fmtMsg(RewardPointsLocale.BulkAddPointTitle)}
                className="bulk-add-points-page__title"
            />
            <Row className="bulk-add-points-page__dragger">
                <Dragger onRemove={clearFile} {...props}>
                    <p className="ant-upload-drag-icon">
                        <Icon type="upload" />
                    </p>
                    <p className="ant-upload-text">
                        {fmtMsg(RewardPointsLocale.RewardPointsDragLabel)}
                    </p>
                </Dragger>
            </Row>
            <Row className="bulk-add-points-page__format-text">
                <div>
                    {fmtMsg({
                        id:
                            RewardPointsLocale.RewardPointsLabelUploadSupportedFormat
                    })}{" "}
                    <a onClick={downloadFormatFile} href="javascript:void(0)">
                        {fmtMsg({
                            id:
                                RewardPointsLocale.RewardPointsLabelUploadFormatLinkText
                        })}
                    </a>
                </div>
            </Row>
            {fileList.length ? (
                <Row className="bulk-add-points-page__summary-text">
                    <span style={{ marginRight: 20 }}>
                        {fmtMsg(
                            RewardPointsLocale.RewardPointsLabelRecordImported
                        )}{" "}
                        {dataUploaded.length}
                    </span>
                    <Popover
                        placement="right"
                        content={
                            <div
                                style={{
                                    maxHeight: "30vh",
                                    overflowY: "scroll",
                                    minWidth: "300px"
                                }}
                            >
                                {invalidRecords.map((record, index) => {
                                    return (
                                        <div key={index}>
                                            <ErrorDetailItem
                                                validCampuses={
                                                    validCampusFromServer
                                                }
                                                item={record}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        }
                        trigger="click"
                        visible={popoverVisible}
                        onVisibleChange={handleVisibleChange}
                    >
                        <span
                            className="bulk-add-points-page__summary-text--errors"
                            onClick={() => setPopoverVisible(true)}
                        >
                            {invalidRecords.length
                                ? `${invalidRecords.length} ${fmtMsg(
                                      RewardPointsLocale.RewardPointsLabelRecordInvalid
                                  )}`
                                : null}
                        </span>
                    </Popover>
                </Row>
            ) : null}
            {loading ? null : (
                <Grid
                    isReadOnly={true}
                    className="bulk-add-points-page__grid"
                    initialized={() => {}}
                    loadedRows={flex => {
                        flex.rows.forEach(row => {
                            if (
                                invalidRecords.findIndex(
                                    record =>
                                        record.order === row.dataItem.order
                                ) > -1
                            ) {
                                row.cssClass = "invalid-row";
                            }
                        });
                    }}
                    paginationSize={20}
                    allowSorting={false}
                    paginationShowTotal={(total, range) =>
                        fmtMsg(
                            { id: GLLocale.Pagination },
                            { from: range[0], to: range[1], total }
                        )
                    }
                    formatItem={formatItem}
                    itemsSource={dataUploaded}
                    preventWijmoGridResponsive={true}
                >
                    {rewardColsGenerator()}
                </Grid>
            )}

            <Row
                style={{
                    position: "absolute",
                    right: 0,
                    bottom: 0,
                    width: "100%",
                    padding: "16px 40px",
                    background: "#fff",
                    textAlign: "right"
                }}
            >
                <Button
                    onClick={redirectToPointManager}
                    style={{
                        marginRight: 8,
                        width: 80,
                        height: 32,
                        fontSize: 14
                    }}
                >
                    {fmtMsg(RewardPointsLocale.RewardPointsLabelCancel)}
                </Button>
                <Button
                    onClick={clearFile}
                    disabled={!fileList.length}
                    type="default"
                    style={{
                        marginRight: 8,
                        width: 80,
                        height: 32,
                        fontSize: 14
                    }}
                    className={classNames({
                        "button-disabled": !fileList.length
                    })}
                >
                    {fmtMsg(RewardPointsLocale.RewardPointsLabelClear)}
                </Button>
                <Button
                    type="primary"
                    disabled={!!invalidRecords.length || !dataUploaded.length}
                    style={{ width: 80, height: 32, fontSize: 14 }}
                    onClick={onCommit}
                    className={classNames({
                        "button-disabled":
                            !!invalidRecords.length || !dataUploaded.length
                    })}
                >
                    {fmtMsg(RewardPointsLocale.RewardPointsLabelCommit)}
                </Button>
            </Row>
        </Container>
    );
};
export default BulkAddPoints;

interface ErrorDetailItemProps {
    item: CampusPointExcelModel;
    validCampuses: ValidateBulkModelResponse[];
}
const ErrorDetailItem = ({ item, validCampuses }: ErrorDetailItemProps) => {
    const { errorsMsg, order } = detectInvalidRecord(validCampuses)(item);
    return (
        <div>
            <h4>
                {fmtMsg(
                    {
                        id:
                            RewardPointsLocale.RewardPointsLabelRecordInvalidDetail
                    },
                    { order }
                )}
            </h4>
            <ul style={{ fontStyle: "italic" }}>
                {errorsMsg &&
                    errorsMsg.map((text, index) => <li key={index}>{text}</li>)}
            </ul>
        </div>
    );
};
