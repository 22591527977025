import { RewardPointsLocale } from "@app/locales/localeid";
import {
    CampusPointExcelModel,
    ValidateBulkModelResponse
} from "@app/service/reward-points";
import { fmtMsg } from "@app/util";
import _ from "lodash";
export const allOption = {
    name: "All",
    id: "all"
};

export const loadingOption = {
    name: "",
    id: "loading"
};

export enum ColumnNames {
    Order = "order",
    SchoolName = "schoolName",
    CampusName = "campusName",
    CampusId = "campusId",
    Description = "description",
    Point = "point",
    CreationDate = "creationDate"
}

export function isValidDateFormat(str) {
    if (typeof str !== "string") {
        return null;
    }
    var t = str.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
    if (t !== null) {
        var d = +t[1],
            m = +t[2],
            y = +t[3];
        var date = new Date(y, m - 1, d);
        if (date.getFullYear() === y && date.getMonth() === m - 1) {
            return date;
        }
    }
    return null;
}

const guidPattern = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export const isGuid = (guid: string) => {
    if (!guid) {
        return false;
    }
    return guid.match(guidPattern) ? true : false;
};

export const isFalsyExceptZero = (value: any) => {
    if (value || value === 0) {
        return false;
    } else {
        return true;
    }
};

export const detectInvalidRecord = (
    validCampuses: ValidateBulkModelResponse[]
) => (record: CampusPointExcelModel) => {
    const errorsMsg = [];
    const campus = {
        campusId: record.campusId,
        schoolName: record.schoolName,
        campusName: record.campusName
    };
    if (!(validCampuses.findIndex(item => _.isEqual(item, campus)) > -1)) {
        errorsMsg.push(fmtMsg(RewardPointsLocale.RewardPointsValidateCampusId));
    }
    for (let [key, value] of Object.entries(record)) {
        switch (key) {
            case "description": {
                if (isFalsyExceptZero(value)) {
                    errorsMsg.push(
                        fmtMsg(RewardPointsLocale.RewardPointsValidateDesc)
                    );
                }
                break;
            }
            case "point": {
                if (isFalsyExceptZero(value)) {
                    errorsMsg.push("Point is missing");
                    break;
                }
                if (value === 0) {
                    errorsMsg.push(
                        fmtMsg(
                            RewardPointsLocale.RewardPointsValidateFormPointZero
                        )
                    );
                    break;
                }
                if (value < -9999 || value > 9999) {
                    errorsMsg.push(
                        fmtMsg(
                            RewardPointsLocale.RewardPointsValidateFormPointRange
                        )
                    );
                    break;
                }
                if (typeof value !== "number") {
                    errorsMsg.push(
                        fmtMsg(RewardPointsLocale.RewardPointsValidatePoint)
                    );
                }
                break;
            }
            case "creationDate": {
                if (typeof value.getMonth !== "function") {
                    if (value || value === 0) {
                        errorsMsg.push(
                            fmtMsg(
                                RewardPointsLocale.RewardPointsValidateDateInvalid
                            )
                        );
                    } else {
                        errorsMsg.push(
                            fmtMsg(
                                RewardPointsLocale.RewardPointsValidateDateMissing
                            )
                        );
                    }
                }
                break;
            }
            default:
                break;
        }
    }
    if (!errorsMsg.length) {
        return null;
    }
    return { order: record.order, errorsMsg };
};
